<template>
  <div class="header">
    <!-- 面包屑 -->
    <div class="left">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item v-for="item in breadList" :key="item.name">{{item.meta.title}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="right"><el-button type="text" @click="handleLoginOut">退出</el-button></div>
  </div>
</template>

<script>
import { removeToken } from '@/util/token'
import { mapGetters } from 'vuex'
export default {
  name: 'top',
  props: {
    nickname: String
  },
  computed: {
    ...mapGetters([
      'userData'
    ])
  },
  data () {
    return {
      breadList: [] // 路由集合
    }
  },
  watch: {
    $route () {
      this.getBreadcrumb()
    }
  },
  created () {
    this.getBreadcrumb()
    this.$http.Auth().then((res) => {
      if (res.code == 200) {
      }else{
        this.$message({
          type: 'warning',
          message: `登录已失效, 请重新登录!`
        })
      }
    }).catch(error => {
    })
  },
  methods: {
    getBreadcrumb () {
      const matched = this.$route.matched
      this.breadList = matched
    },
    handleLoginOut () {
      this.$confirm('您将退出系统, 是否继续?', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning'
      }).then(() => {
        localStorage.removeItem("login")
        this.$router.push('/')
      }).catch(() => {});
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  display: flex;
  height: 50px;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
}
</style>
