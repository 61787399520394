import apiList from '../../util/request/api'

const user = {
  state: {
    data: undefined,
    path: ''
  },
  mutations: {
    SET_DATA: (state, data) => {
      state.data = data
    },
    SET_PATH: (state, data) => {
      state.path = data
    }
  },
  actions: {
    GetUserInfo ({
      commit
    }) {
      return new Promise((resolve, reject) => {
        // console.log("获取登录信息")
        // apiList.getInfo().then(res => {
        //   commit('SET_DATA', res)
        //   resolve()
        // }).catch(error => {
        //   reject(error)
        // })
      })
    }
  }
}

export default user
