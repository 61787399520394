import axios from 'axios'
import {
  getToken,
  setToken
} from '../token'
import router from '@/router/index.js'
import { Message } from 'element-ui'

// axios 配置
const root = process.env.VUE_APP_API
axios.defaults.timeout = 30000
axios.defaults.baseURL = root
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  // 发送请求之前处理
  config.headers.Token = `${localStorage.getItem("login")}`
  config.headers.Accept = 'application/x.myapp.v1+json'
  return config
}, function (error) {
  // 请求错误处理
  return Promise.reject(error)
})

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // updateToken
  if (response.headers.updatetoken) {
    localStorage.setItem("login", response.headers.updatetoken)
  }
  // 响应数据处理
  if (response.data.code == 999) {
    localStorage.removeItem("login")
    router.push('/login')
  }
  return response.data
}, function (error) {
  // 响应错误处理
  return Promise.reject(error)
})

// GET 请求
export function GET (url, params) {
  return axios.get(url, {
    params: params
  })
}

// POST 请求
export function POST (url, data, params) {
  return axios.post(url, data, params)
}

// put 请求
export function PUT (url, data, params) {
  return axios.put(url, data, params)
}

// delete 请求
export function DELETE (url, data) {
  return axios.delete(url, data)
}

// patch 请求
export function PATCH (url, data) {
  return axios.patch(url, data)
}
