import { GET, POST } from '../http'

const product = {
  getProductList: (data) => GET('/product/list', data),
  getProductOne: (data) => GET('/product/one', data),
  getProductAll: (data) => GET('/product/all', data),
  addProductData: (data) => POST('/product/add', data),
  updateProductData: (data) => POST(`/product/update`, data),
  delProductData: (data) => GET(`/product/delete`, data)
}

export default product