import store from '../store/index'
import { getToken, removeToken } from '@/util/token'
export default function (to, from, next) {
  if (to.name === 'login' && getToken()) {
    next({
      name: 'start'
    })
  }
  store.commit('SET_PATH', to.path)
  next()
}