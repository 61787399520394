import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import children from '@/router/children'
import beforeEach from './beforeEach.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'start',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录'
    },
    component: () => import('@/views/login/login')
  },
  {
    path: '/home',
    name: 'Home',
    redirect: '/index',
    component: Home,
    children: [
      { // 首页
        path: '/index',
        name: 'index',
        hidden: true,
        meta: {
          title: '首页'
        },
        component: () => import('@/views/login/Index.vue')
      },
      ...children
    ]
  }
]

// 路由报错
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
// if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
// return originalPush.call(this, location).catch(err => err)
// }
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach(beforeEach)
export default router
