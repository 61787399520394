import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import ElementUI from 'element-ui'
import Pagination from '@/components/Pagination'
import apiList from './util/request/api'
import './style/element-variables.scss'
import './style/quill.snow.scss'
import {Base64} from 'js-base64'

Vue.config.productionTip = false
Vue.prototype.$http = apiList
Vue.use(ElementUI)
Vue.component('Pagination', Pagination)
Vue.prototype.$Base64 = Base64;
Vue.prototype.$webhost = "https://www.yaopower.com"
if (window.location.hash !== '#/login' && window.location.hash !== '#/') {store.dispatch('GetUserInfo')}
Vue.prototype.$uploadHeaders = function () {return {Token: `${localStorage.getItem("login")}`}}
Vue.prototype.$getCurrentDateTime = function () {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const day = currentDate.getDate();
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const seconds = currentDate.getSeconds();
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
