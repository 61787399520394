export default [
  {
    path: '/report',
    name: 'report_index',
    meta: { title: '医药报告', icon: "el-icon-document-add" },
    component: () => import('@/views/report'),
    children: [
      {
        path: '/report/report',
        name: 'report',
        meta: { title: '报告管理' },
        component: () => import('@/views/report/report')
      },{
        path: '/report/classify',
        name: 'classify',
        meta: { title: '报告分类' },
        component: () => import('@/views/report/classify'),
      },{
        path: '/report/label',
        name: 'label',
        meta: { title: '报告标签' },
        component: () => import('@/views/report/label'),
      }
    ]
  },{
    path: '/news',
    name: 'news_index',
    meta: { title: '医药资讯', icon: "el-icon-set-up" },
    component: () => import('@/views/news'),
    children: [
      {
        path: '/news/news',
        name: 'news',
        meta: { title: '资讯管理' },
        component: () => import('@/views/news/news'),
      }
    ]
  },{
    path: '/meeting',
    name: 'meeting_index',
    meta: { title: '会议集锦', icon: "el-icon-s-opportunity" },
    component: () => import('@/views/meeting'),
    children: [
      {
        path: '/meeting/meeting',
        name: 'meeting',
        meta: { title: '会议管理' },
        component: () => import('@/views/meeting/meeting'),
      },{
        path: '/meeting/classify',
        name: 'classify',
        meta: { title: '会议分类' },
        component: () => import('@/views/meeting/classify'),
      }
    ]
  },{ // 订单管理
    path: '/order',
    name: 'order',
    meta: { title: '订单中心', icon: "el-icon-shopping-cart-2" },
    component: () => import('@/views/order'),
    children: [
      {
        path: '/order/buy',
        name: 'userList',
        meta: { title: '订单管理' },
        component: () => import('@/views/order/buy'),
      },{
        path: '/order/invoice',
        name: 'adminList',
        meta: { title: '发票管理' },
        component: () => import('@/views/order/invoice'),
      }
    ]
  },{ // 用户管理
    path: '/user',
    name: 'user',
    meta: { title: '用户中心', icon: "el-icon-user" },
    component: () => import('@/views/user'),
    children: [
      {
        path: '/user/user',
        name: 'userList',
        meta: { title: '用户管理' },
        component: () => import('@/views/user/user-list'),
      },{
        path: '/user/admin',
        name: 'adminList',
        meta: { title: '管理员' },
        component: () => import('@/views/user/admin-list'),
      }
    ]
  },{ // 设置中心
    path: '/settings',
    name: 'settings',
    meta: { title: '管理中心', icon: "el-icon-setting" },
    component: () => import('@/views/settings'),
    children: [
      {
        path: '/settings/page',
        name: 'settingsList',
        meta: { title: '设置管理' },
        // component: () => import('@/views/user/user-list'),
      }
    ]
  }
]