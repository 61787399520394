import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import getters from './getters'
import createPersistedState from 'vuex-persistedstate' // vuex持久化防止刷新数据丢失插件

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user
  },
  getters,
  plugins: [createPersistedState({
    paths: ['data']
  })]
})
