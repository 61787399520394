import { POST } from '../http'

// 登录认证
const login = {
  // 登录
  loginByPassword: (data) => POST('/api/login', data),
  // 验证用户信息
  Auth: (data) => POST('/api/auth', data)
}

export default login