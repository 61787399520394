import login from './login/login'
import user from './user/userList'
import news from './news/news'
import brand from './brand/brand'
import category from './category/category'
import product from './product/product'
import solution from './solution/solution'
import setting from './setting/setting'
import crud from './crud/crud'
import order from './order/order'
const apiList = {
  ...login,
  ...user,
  ...news,
  ...brand,
  ...category,
  ...product,
  ...solution,
  ...setting,
  ...crud,
  ...order
}

export default apiList
