import { GET, POST } from '../http'

const solution = {
  getSolutionList: (data) => GET('/solution/list', data),
  getSolutionOne: (data) => GET('/solution/one', data),
  addSolutionData: (data) => POST('/solution/add', data),
  updateSolutionData: (data) => POST(`/solution/update`, data),
  delSolutionData: (data) => GET(`/solution/delete`, data)
}

export default solution