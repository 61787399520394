<template>
<el-menu
    :default-active="defaultActive"
    class="el-menu-vertical-demo"
    background-color="#1f2d3d"
    text-color="#fff"
    active-text-color="#ffd04b"
  >
    <div class="logo">
      <a href="/index">
        <!-- <img src="../../../src/assets/logo.png" /> -->
        <h1 class="logo-title"><span>CMS</span>管理系统</h1>
      </a>
    </div>
    <router-link
      :to="'/index'"
    >
      <el-menu-item :index="`${index}`" v-show="false">
        <i class="el-icon-menu"></i>
        <span slot="title">首页</span>
      </el-menu-item>
    </router-link>
    <el-submenu
      v-for="(nav, navIndex) in sideBar"
      :key="`nav-${nav.name}`"
      :index="`${navIndex + 1}`"
    >
      <template slot="title">
        <i :class="nav.meta.icon"></i>
        <span>{{nav.meta.title}}</span>
      </template>
      <div v-for="(subNav, subNavIndex) in nav.children" :key="subNavIndex">
        <router-link :to="subNav.path">
          <el-menu-item :index="`${navIndex + 1}-${subNavIndex + 1}`">{{subNav.meta.title}}</el-menu-item>
        </router-link>
      </div>
    </el-submenu>
  </el-menu>
</template>

<script>
// import { mapGetters } from 'vuex'
import aside from '@/router/children'
export default {
  computed: {
    // ...mapGetters([
    //   'userNav'
    // ])
    sideBar () {
      return aside
    }
  },
  mounted () {
    // 刷新后导航高亮
    setTimeout(() => {
      this.setLight()
    }, 800)
  },
  watch: {
    $route (val) {
      this.setLight()
    }
  },
  data () {
    return {
      defaultActive: '0',
      index: 0,
      navIndex: '',
      subNavIndex: '',
      pageIndex: ''
    }
  },
  methods: {
    // 设置导航高亮
    setLight () {
      this.sideBar.map((nav, navIndex) => {
        nav.children.map((subNav, subNavIndex) => {
          if (subNav.children === undefined) {
            if (subNav.path === this.$store.state.user.path) {
              this.navIndex = navIndex + 1
              this.subNavIndex = subNavIndex + 1
            }
          } else {
            subNav.children.map((page, pageIndex) => {
              if (page.path === this.$store.state.user.path) {
                this.navIndex = navIndex + 1
                this.subNavIndex = subNavIndex + 1
                this.pageIndex = pageIndex + 1
              }
            })
          }
        })
      })
      if (this.pageIndex === '') {
        this.defaultActive = this.navIndex + '-' + this.subNavIndex
      } else {
        this.defaultActive = this.navIndex + '-' + this.subNavIndex + '-' + this.pageIndex
      }
    }
  }
}

</script>

<style scoped lang="scss">
.logo {
  width: 180px;
  text-align: center;
  padding-top: 20px;
  img {
    width: 120px;
  }
}
.el-menu {
  height: 100vh;
  .el-submenu .el-menu-item {
    min-width: 0;
  }
  .el-menu-item.is-active {
    color: #07aea0!important;
  }
}
.logo-title {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  span {
    color:#07aea0;
    padding-right: 2px;
  }
}
</style>
