import { GET, POST } from '../http'

const news = {
  getNewsList: (data) => GET('/news/list', data),
  getNewsOne: (data) => GET('/news/one', data),
  addNewsData: (data) => POST('/news/add', data),
  updateNewsData: (data) => POST(`/news/update`, data),
  delNewsData: (data) => GET(`/news/delete`, data)
}

export default news