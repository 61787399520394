import { GET, POST } from '../http'

const brand = {
  getBrandList: (data) => GET('/brand/list', data),
  getBrandALL: (data) => GET('/brand/all', data),
  addBrandData: (data) => POST('/brand/add', data),
  updateBrandData: (data) => POST(`/brand/update`, data),
  delBrandData: (data) => GET(`/brand/delete`, data)
}

export default brand