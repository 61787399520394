import { GET, POST } from '../http'

const category = {
  getCategoryList: (data) => GET('/category/list', data),
  getCategoryAll: (data) => GET('/category/all', data),
  getCategoryAllData: (data) => GET('/category/alldata', data),
  addCategoryData: (data) => POST('/category/add', data),
  updateCategoryData: (data) => POST(`/category/update`, data),
  delCategoryData: (data) => GET(`/category/delete`, data)
}

export default category