import { GET, POST } from '../http'

const crud = {
  getCrudList: (data) => GET('/api/read', data),
  getCrudOne: (data) => POST('/api/readone', data),
  addCrudData: (data) => POST('/api/create', data),
  updateCrudData: (data) => POST(`/api/update`, data),
  delCrudData: (data) => POST(`/api/delete`, data),
  getUploadImg: (data) => GET('/api/upload', data),
}

export default crud