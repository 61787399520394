<template>
  <div class="home">
    <el-container>
      <el-aside width="180px">
        <Side />
      </el-aside>
      <el-container>
        <el-header>
          <Top v-bind="currentUser"/>
        </el-header>
        <el-main>
          <router-view v-bind="currentUser" />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Side from './layout/Aside'
import Top from './layout/Header'
export default {
  name: 'home',
  data () {
    return {
      currentUser: {
        id: 0,
        nickname: '',
        status: 'UNKNOWN'
      }
    }
  },
  components: {
    Side,
    Top
  }
}
</script>
<style lang="scss" scoped>
.el-header {
  padding: 0;
}
.home {
  height: 100%;
  .el-container{
    height: 100%;
  }
}
.el-aside {
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
